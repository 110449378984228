<template>
  <div class="repair">
    <el-form :inline="true" class="demo-form-inline">
    <el-form-item label="wap远程维修预约价格" required>
      <el-input placeholder="请输入单价￥" v-model="price">
        <template v-slot:prepend>单价￥</template>
      </el-input>
    </el-form-item>
    <el-form-item label="原价￥">
      <el-input v-model="rawPrice" placeholder="请输入原价￥">
        <template v-slot:prepend>原价￥</template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="saveShuajiProductFn">保存</el-button>
    </el-form-item>
    <el-form-item style="display:block;"></el-form-item>
    <el-form-item label="选择导出预约单的时间">
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="defaultTime1"
          value-format="YYYY-MM-DD h:m:s"
        />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="exportShuajiOrderFn">导出</el-button>
    </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { saveShuajiProduct, findShuajiProduct } from "../../api/index";
import { toRaw } from "@vue/reactivity";
import axios from "axios";
export default {
  data() {
    return {
      value1: "",
      rawPrice: "",
      price: "",
      defaultTime1: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ]
    };
  },
  async mounted() {
    this.findShuajiProductFn();
  },
  methods: {
    saveShuajiProductFn() {
      let params = {};
      params.rawPrice = this.rawPrice;
      params.price = this.price;
      if (parseInt(params.price) > parseInt(params.rawPrice)) {
        return this.$message.error("单价不能大于原价");
      }
      console.log("params", params);
      saveShuajiProduct(params).then(res => {
        if (res.code == 0) {
          return this.$message({
            message: "修改成功",
            type: "success"
          });
        } else {
          return this.$message.error(res.message);
        }
      });
    },
    findShuajiProductFn() {
      findShuajiProduct().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.rawPrice = res.data.rawPrice;
          this.price = res.data.price;
        }
      });
    },
    exportShuajiOrderFn() {
      console.log("data", toRaw(this.value1));
      let params = {};
      let startTime = this.value1[0];
      startTime = new Date(startTime).toLocaleString();
      console.log("startTime", startTime);
      startTime = startTime.replace(/\//g, "-");
      let endTime = this.value1[1];
      endTime = new Date(endTime).toLocaleString();
      endTime = endTime.replace(/\//g, "-");
      params = {
        startTime,
        endTime
      };
      console.log("params", params);
      let url =
        process.env.VUE_APP_BASE_MIAOHUI +
        "/miaohui/admin/order/exportShuajiOrder";
      // excel 表格下载
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json; application/octet-stream"
        },
        url: url,
        params: params,
        responseType: "arraybuffer"
      })
        .then(res => {
          let b = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let url = URL.createObjectURL(b);
          let link = document.createElement("a");
          link.download = "订单数据" + ".xls";
          link.href = url;
          link.click();
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss">
.repair {
}
</style>